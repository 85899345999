import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';

export const useEditWebsite = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const auth = useAuthUser();

  const editWebsite = async ({
    name,
    url,
    industry,
    page_builder,
    platform,
    ecommerce,
    phone,
    email,
    frontend_debug = 0,
    enable_recording = 0,
    force_screenshot = 0,
    backend_debug = 0,
    tracking,
    siteId
  }: {
    name?: string;
    url?: string;
    currency?: string;
    platform?: string;
    industry?: string;
    phone?: string;
    email?: string;
    ecommerce?: number;
    page_builder?: string;
    frontend_debug?: string | number;
    backend_debug?: string | number;
    enable_recording?: string | number;
    force_screenshot?: string | number;
    siteId: string;
    tracking?: number;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateWebsite',
          name,
          url,
          siteId,
          platform,
          phone,
          email,
          industry,
          page_builder,
          ecommerce,
          tracking,
          currency: 'USD',
          frontend_debug: auth()?.super_user == 1 ? frontend_debug : null,
          enable_recording: auth()?.super_user == 1 ? enable_recording : null,
          force_screenshot: auth()?.super_user == 1 ? force_screenshot : null,
          backend_debug: auth()?.super_user == 1 ? backend_debug : null,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error updating website');
      setLoading(false);
    }
  };
  const flagWebsite = async ({
    flag,
    comment,
    siteId
  }: {
    flag?: number;
    comment?: string;
    siteId: number;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.flagWebsite',
          siteId,
          comment,
          flag,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error flagging website');
      setLoading(false);
    }
  };

  const submitWebsiteSurvey = async ({ settings }: { settings: any }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateWebsite',
          settings,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error submitting website survey');
      setLoading(false);
    }
  };

  return { editWebsite, submitWebsiteSurvey, flagWebsite, loading, errorMessage };
};
