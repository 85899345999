import Modal from '../../components/global/ui/Modal';
import { IFeedback } from '../../interfaces/support-ticket';

export default function ViewFeedback({
  data,
  closeModal
}: {
  data: IFeedback;
  closeModal: () => void;
}) {
  return (
    <Modal closeModal={closeModal} title={''} width={'lg:w-2/3'}>
      <div>
        <div className={'mb-3'}>
          <h6 className={'text-xs'}>Submitted by</h6>
          <h6 className={'font-medium text-sm'}>{data.full_name}</h6>
        </div>
        <div className={'bg-gray-100 text-sm p-5 rounded min-h-[30vh]'}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.message as string
            }}></div>
        </div>
      </div>
    </Modal>
  );
}
