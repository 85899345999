import { useState } from 'react';

import { IFeedback } from '../../interfaces/support-ticket';
import moment from 'moment';
import ViewFeedback from './ViewFeedback';

export default function FeedbackListCard({ data }: { data: IFeedback }) {
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  return (
    <>
      {feedbackOpen && <ViewFeedback data={data} closeModal={() => setFeedbackOpen(false)} />}
      <div
        onClick={() => setFeedbackOpen(true)}
        className={`border rounded-md border-gray-200 cursor-pointer hover:border-gray-400 bg-white hover:shadow p-3 md:p-3`}>
        <div className={'flex justify-between'}>
          <div
            className={
              'flex justify-start gap-2 text-gray-700 rounded-full px-2 py-1 border bg-gray-100'
            }>
            <span className={''}>
              <i className="fi fi-rr-user text-sm"></i>
            </span>
            <span>
              <h5 className={'text-sm'}>{data.full_name}</h5>
            </span>
          </div>
          <div>
            <span className={'text-sm italic'}>{moment(data.feedback_date).format('lll')}</span>
          </div>
        </div>
        <div className={'mt-2'}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.message as string
            }}
            className={'text-sm truncate'}></div>
        </div>
      </div>
    </>
  );
}
